import { ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import { Rings } from  'react-loader-spinner';
import { scaleColors, theme } from '../layout/theme';

export interface LoadingAnimationProps {
  loadingText?: string
}

/**
 * Primary UI component for user interaction
 */
export const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  loadingText = 'Loading',
  ...props
}) => {

  return (
    <ThemeProvider theme={theme}>
      <div style={{height: '100%'}}>
        <div style={{margin: '0',
        position: 'absolute',
        top: '50%',
        left: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        msTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)'}}>
          <Rings
          height="80"
          width="80"
          color={scaleColors.Brand}
          radius="6"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="rings-loading"/>
          <Typography>{loadingText}</Typography>
        </div>
      </div>
    </ThemeProvider>
  );
};