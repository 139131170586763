import React, { CSSProperties, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MaturityCategory, MaturityDescriptionExtended, MaturityLevel } from '../../model/ScaleTypes';
import Joyride, { Step } from 'react-joyride';
import { MATURITY_CATEGORY_COLORS, MATURITY_CATEGORY_COLORS_RESOLVED } from '../Colors';
import { MaturityDescriptionTableCell } from './MaturityDescriptionTableCell';
import { CategoryTitleCell } from './CategoryTitleCell';
import { Typography } from '@mui/material';
import { maturityTableTutorialSteps } from './MaturityTableTutorial';
import { ScoresByCategories } from '../../pages/MaturityView';

const verticalCellStyle: CSSProperties = {
  writingMode: 'vertical-rl',
  transform: 'rotate(180deg)',
  transformOrigin: 'center center',
  whiteSpace: 'nowrap',
  padding: '4px',
  fontWeight: 'bold'
}

export interface MaturityTableProps {
  categories: MaturityCategory[];
  levels: MaturityLevel[];
  descriptions: MaturityDescriptionExtended[];
  modelData: ScoresByCategories;
  updatedAt: number;
  hiddenLevels?: number[];
  onClickBack?: () => void;
  onClickExport?: () => void;
  onClickDescriptionCheckbox?: (clickedCategoryDescription: MaturityDescriptionExtended) => void;
  freemium?: boolean;
}

export const MaturityTable: React.FC<MaturityTableProps> = ({
  categories = [],
  levels = [],
  descriptions = [],
  modelData = {},
  updatedAt,
  freemium = false,
  hiddenLevels = [],
  onClickBack = () => { },
  onClickExport = () => { },
  onClickDescriptionCheckbox = (clickedCategoryDescription: MaturityDescriptionExtended) => { },
}) => {

  const [transpose, setTranspose] = React.useState<boolean>(false)
  const [run, setOnboardingRun] = React.useState<boolean>(false);
  const [steps, setSteps] = React.useState<Step[]>([]);

  const [myHiddenLevels, setMyHiddenLevels] = React.useState<number[]>(hiddenLevels);
  const [myHiddenCategories, setMyHiddenCategories] = React.useState(new Set<number>([]));

  const [resetCounter, setResetCounter] = React.useState<number>(0);

  useEffect(() => {
    setMyHiddenLevels(hiddenLevels);
  }, [hiddenLevels]);

  const handleReset = () => {
    setResetCounter(prevCounter => prevCounter + 1);
  };

  // hide categories that have all shown levels checked
  useEffect(() => {
    const shownLevels = levels.filter(level => myHiddenLevels.indexOf(level.level) === -1);
    const highestShownLevel = Math.max(...shownLevels.map(lvl => lvl.level));
    const hiddenCategories = new Set<number>();
    categories.forEach(category => {
      // if the category's level is higher or equal to highest then it is hidden.
      if (highestShownLevel <= modelData[category.id]) hiddenCategories.add(category.order);
    });
    setMyHiddenCategories(hiddenCategories);
  }, [myHiddenLevels, levels, categories, modelData, resetCounter]);

  useEffect(() => {
    setSteps(maturityTableTutorialSteps);
  }, []);

  const handleToggleHiddenLevels = () => {
    myHiddenLevels.length > 0 && setMyHiddenLevels([]);
    myHiddenLevels.length === 0 && setMyHiddenLevels(hiddenLevels);
  };

  document.title = "Scale SaaS - Maturity Assessment";

  const matrix: {
    [maturityCategoryId: string]: { [maturityLevelId: string]: MaturityDescriptionExtended }
  } = {};
  descriptions.forEach(desc => matrix[desc.maturityCategoryId] = {});
  descriptions.forEach(desc => matrix[desc.maturityCategoryId][desc.maturityLevelId] = desc);

  const shownLevels = useMemo(() => levels.filter(level => !myHiddenLevels.includes(level.level)), [myHiddenLevels, levels]);
  const shownCategories = useMemo(() => categories.filter(category => !myHiddenCategories.has(category.order)), [myHiddenCategories, categories]);


  const ToggleLevelsButtonHorizontal = () => <Button size="small" variant="text" style={{ width: '100%' }} color="primary" onClick={handleToggleHiddenLevels}>
    {myHiddenLevels.length > 0 ? "Show All Levels" : "Hide Levels outside of Current Milestone"}</Button>

  const ToggleLevelsButtonVertical = () => <Button size='small' style={{ minWidth: 0, height: "100%", padding: '0px', margin: '0px' }} variant='text'
    color="primary" onClick={handleToggleHiddenLevels}>
    {myHiddenLevels.length > 0 ? "Show All Levels" : "Hide Levels outside of Current Milestone"}</Button>


  const ActionButtonsCell = () => (
    <TableCell align="center">
      <Button variant="outlined" style={{ width: '100px' }} onClick={onClickBack}>Back</Button>
      <Button variant="outlined" style={{ width: '100px' }} onClick={onClickExport}>Export</Button>
    </TableCell>
  )

  const NormalTable = () =>
    <Table size="small">
      <TableHead>
        <TableRow className="categories">
          <ActionButtonsCell />
          {categories.map(category => <CategoryTitleCell key={category.id} colSpan={2} category={category} skipped={!shownCategories.some(cat => cat.id === category.id)} />)}
          <ActionButtonsCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {hiddenLevels.length > 0 &&
          <TableRow key="showAllAbove">
            <TableCell colSpan={categories.length * 2 + 2} align="center">
              <ToggleLevelsButtonHorizontal />
            </TableCell>
          </TableRow>
        }
        {shownLevels.map((level, rowIndex) =>
          <TableRow key={level.id}>
            <TableCell component="th" scope="row" className={"levelName_" + level.level} >{level.level} {level.name}</TableCell>

            {categories.map((category, i) => {
              const isShown = shownCategories.some(cat => cat.id === category.id);
              if (isShown) return <MaturityDescriptionTableCell key={i}
                maturityDescription={matrix[category.id][level.id]}
                state={modelData[category.id] ? (modelData[category.id] < level.level ? "unchecked" : "checked") : "unchecked"}
                onClickDescriptionCheckbox={onClickDescriptionCheckbox}
                updatedAt={updatedAt}
                freemium={freemium}
              />
              if (rowIndex > 0) return null; // only first is drawn, is it has colspan
              return <TableCell key={i} colSpan={2} rowSpan={shownLevels.length}
                style={{
                  ...verticalCellStyle,
                  backgroundColor: MATURITY_CATEGORY_COLORS_RESOLVED[category.order - 1],
                  color: 'white'
                }}
                align="center">
                <Button size='small' style={{ color: 'white', minWidth: 0, height: "100%", padding: '0px', margin: '0px' }} variant='text'
                  onClick={() => setMyHiddenCategories(prevSet => {
                    const set = new Set(prevSet);
                    set.delete(category.order)
                    return set;
                  })}
                >{category.name} ★ RESOLVED</Button>
              </TableCell>
            }
            )}

            <TableCell>{level.level} {level.name}</TableCell>
          </TableRow>
        )}
        {hiddenLevels.length > 0 &&
          <TableRow key="showAllBelow">
            <TableCell colSpan={categories.length * 2 + 2} align="center">
              <ToggleLevelsButtonHorizontal />
            </TableCell>
          </TableRow>
        }
        {/* This is the bottom row */}
        <TableRow>
          <ActionButtonsCell />
          {categories.map(category => <CategoryTitleCell colSpan={2} key={category.id} category={category} skipped={!shownCategories.some(cat => cat.id === category.id)} />)}
          <ActionButtonsCell />
        </TableRow>
      </TableBody>
    </Table >

  const reversedShownLevels = shownLevels.slice().reverse();

  const TransposedTable = () => <Table size="small">
    <TableHead></TableHead>
    <TableBody>
      <TableRow className="levels">
        <ActionButtonsCell />
        {hiddenLevels.length > 0 && <TableCell rowSpan={2 + categories.length}
          style={verticalCellStyle}
          align="center">
          <ToggleLevelsButtonVertical />
        </TableCell>}
        {reversedShownLevels.map((level, colIndex) =>
          <TableCell colSpan={2} width="200px" align="center" className={"levelName_" + level.level}>
            <Typography minWidth={200}>{level.level} {level.name}</Typography>
          </TableCell>
        )}
        {hiddenLevels.length > 0 && <TableCell rowSpan={2 + categories.length}
          style={verticalCellStyle}
          align="center">
          <ToggleLevelsButtonVertical />
        </TableCell>}
        <ActionButtonsCell />
      </TableRow>
      {categories.map((category, colIndex) => {
        const isShown = shownCategories.some(cat => cat.id === category.id);

        if (!isShown) {
          return <TableRow key={category.id}>

            <TableCell align="center" style={{
              backgroundColor: MATURITY_CATEGORY_COLORS[category.order - 1],
              color: 'white'
            }}>{category.name}</TableCell>
            <TableCell key={colIndex} colSpan={reversedShownLevels.length * 2}
              style={{
                backgroundColor: MATURITY_CATEGORY_COLORS_RESOLVED[category.order - 1],
                color: 'white'
              }}
              align="center">
              <Button size='small' style={{ color: 'white', minHeight: 0, width: "100%", padding: '0px', margin: '0px' }} variant='text'
                onClick={() => setMyHiddenCategories(prevSet => {
                  const set = new Set(prevSet);
                  set.delete(category.order)
                  return set;
                })}
              >{category.name} ★ RESOLVED</Button>
            </TableCell>
            <TableCell align="center" style={{
              backgroundColor: MATURITY_CATEGORY_COLORS[category.order - 1],
              color: 'white'
            }}>{category.name}</TableCell>

          </TableRow>
        }
        return <TableRow key={category.id}>
          <CategoryTitleCell key={category.id} category={category} skipped={false} />
          {reversedShownLevels.map((level, colIndex) =>
            <MaturityDescriptionTableCell key={colIndex}
              maturityDescription={matrix[category.id][level.id]}
              state={modelData[category.id] ? (modelData[category.id] < level.level ? "unchecked" : "checked") : "unchecked"}
              onClickDescriptionCheckbox={onClickDescriptionCheckbox}
              updatedAt={updatedAt}
              freemium={freemium}
            />
          )}
          <CategoryTitleCell key={category.id} category={category} skipped={false} />
        </TableRow>
      }
      )}

      {/* This is the bottom row */}
      <TableRow>
        <ActionButtonsCell />
        {reversedShownLevels.map((level, colIndex) =>
          <TableCell colSpan={2} width="500px" align='center' className={"levelName_" + level.level}>{level.level} {level.name}</TableCell>
        )}
        <ActionButtonsCell />
      </TableRow>

    </TableBody>
  </Table>

  return <TableContainer className="maturityTableCont" id="maturityTable" component={Paper}>
    <Joyride
      continuous={true}
      run={run}
      scrollToFirstStep={true}
      showSkipButton={false}
      steps={steps}
      styles={{
        overlay: {
          width: document.body.scrollWidth
        },
        options: {
          zIndex: 10000,
        },
      }}
    />
    <Button onClick={() => { setMyHiddenLevels([]); setOnboardingRun(!run); }}>Tutorial</Button>
    <Button onClick={() => { setTranspose(!transpose) }}>Transpose</Button>
    <Button onClick={handleReset}>Collapse</Button>
    {transpose ? <TransposedTable /> : <NormalTable />}
  </TableContainer>
};

