import React, { useState } from 'react';

const BUCKET_NAME = "scale_images_eu_1"

const ImageUploader: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
  
    try {
      const response = await fetch(`https://storage.googleapis.com/${BUCKET_NAME}/folder/${selectedFile.name}`, {
        method: 'PUT',
        body: selectedFile, // Send the file directly, not as FormData
        headers: {
          'Content-Type': selectedFile.type, // Set the correct content type
        },
      });
  
      if (response.ok) {
        const uploadedImageUrl = `${BUCKET_NAME}/folder/${selectedFile.name}`;
        setImageUrl(uploadedImageUrl); 
      } else {
        const errorText = await response.text(); // Get the error message
        console.error('Error uploading image:', errorText, response.statusText);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };
  
  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={!selectedFile}>Upload</button>

      {imageUrl && (
        <div>
          <h2>Uploaded Image:</h2>
          <img src={`https://storage.googleapis.com/${imageUrl}`} alt="Uploaded" style={{ maxWidth: '100%' }} />
          <img src={`https://storage.cloud.google.com/${imageUrl}`} alt="Uploaded" style={{ maxWidth: '100%' }} />
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
