import React, { useState } from 'react';
import { MaturityScore, isResolved } from '../../model/ScaleTypes';
import { Card, CardContent, CardHeader, IconButton, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { CARD_LIGHT, RESOLVED_BRIGHT } from '../Colors';

export interface HistoryNoteCardProps {
  note: MaturityScore;
  onMaturityNoteUpdate?: (noteToUpdate: MaturityScore) => void;
  onMaturityNoteDelete?: (noteToDelete: MaturityScore) => void;
}

enum ActionMode { HIDDEN, SHOWN }

export const HistoryNoteCard: React.FC<HistoryNoteCardProps> = ({
  note,
  onMaturityNoteUpdate,
  onMaturityNoteDelete,
  ...props
}) => {
  const isEditable = !!onMaturityNoteUpdate;
  const [actionMode, setActionMode] = useState<ActionMode>(ActionMode.HIDDEN);
  const [editedNote, setEditedNote] = useState(note ? note.notes : "");
  const [showEditDialog, setShowEditDialog] = useState(false);

  const handleEditClick = () => setShowEditDialog(true);

  const handleSave = () => {
    if (!onMaturityNoteUpdate) {
      console.error("Tried to save, but no update handler given.")
      return;
    }
    note.notes = editedNote;
    onMaturityNoteUpdate(note);
    setShowEditDialog(false);
    setActionMode(ActionMode.HIDDEN);
  };

  const handleCancel = () => {
    setEditedNote(note.notes);
    setShowEditDialog(false);
    setActionMode(ActionMode.HIDDEN);
  };

  const iconStyle = { fontSize: "12px" };

  const standardActions = (
    <>
      <IconButton size="small" onClick={handleEditClick}><EditIcon style={iconStyle} /></IconButton>
    </>
  );

  if (!note) return (
    <Card {...props}>
      <CardContent style={{ backgroundColor: CARD_LIGHT }}>
        <Typography display="block" style={{ textAlign: 'center' }}>No note to show</Typography>
      </CardContent>
    </Card>
  );

  const status = <Button size="small" disabled color="primary" variant='contained' style={{
    fontSize: 'calc(100% - 3px)', padding: '0px',
    backgroundColor: isResolved(note) ? RESOLVED_BRIGHT : CARD_LIGHT,
    color: '#253241'
  }}>
    {isResolved(note) ? 'resolved' : 'unresolved'}
  </Button>

  const headerContent = <Typography>
    Level {note.notesScore} {status} {new Date(note.createdAt).toLocaleString()} {(note.createdAt !== note.modifiedAt ? ' (updated)' : '')}
  </Typography>


  if (!note.notes) return (
    <Card {...props}>
      <CardHeader subheader={headerContent} />
    </Card>
  )

  return (
    <Card key={note.id}
      onMouseEnter={() => isEditable && setActionMode(ActionMode.SHOWN)} onMouseLeave={() => setActionMode(ActionMode.HIDDEN)}
      style={{ borderColor: isResolved(note) ? "lightgreen" : CARD_LIGHT }}
      {...props}
    >
      <CardHeader action={actionMode === ActionMode.SHOWN ? standardActions : undefined} subheader={headerContent} />
      <CardContent style={{ paddingTop: "0px" }}>
        <Typography display="block" whiteSpace="pre-line" style={{ marginBottom: '5px' }}>{editedNote}</Typography>
      </CardContent>
      <Dialog
        open={showEditDialog}
        onClose={() => handleCancel()}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Edit note</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              style={{ minWidth: "400px" }}
              fullWidth
              multiline
              minRows={8}
              variant="outlined"
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel()} color="primary">Cancel</Button>
          <Button onClick={handleSave} color="primary" autoFocus>Save</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
