import { useTheme } from '@mui/material';
import chroma from 'chroma-js';
import React from 'react';

interface HorizontalBarProps {
  fontSize?: number;
  percentage: number;
  showText?: boolean;
  text?: string;
  color?: string;
  width?: number;
}

const HorizontalBar: React.FC<HorizontalBarProps> = ({
  percentage,
  fontSize,
  showText = false,
  text,
  color = "#4caf50",
  width = 100 }) => {
  const theme = useTheme();
  const usedFontSize = fontSize ? fontSize : theme.typography.fontSize;

  const barHeight = 12 * usedFontSize / 10;
  const barWidth = width;
  const actualWidth = (percentage / 100) * barWidth;

  const textColor = percentage < 50 || chroma(color).luminance() > 0.5 ? '#000' : '#fff';
  return (
    <svg width={barWidth} height={barHeight} viewBox={`0 0 ${barWidth} ${barHeight}`}>
      <rect width={barWidth} height={barHeight} fill="#ddd" />
      <rect width={actualWidth} height={barHeight} fill={color} />
      {showText &&
        <text fill={textColor} style={{ pointerEvents: 'none' }} x="50%" y="50%" dy=".3em" textAnchor="middle" fontSize={`${usedFontSize}px`} >
          {text}
        </text>
      }
    </svg>
  );
};

export default HorizontalBar;