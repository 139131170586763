import * as htmlToImage from 'html-to-image';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { BusinessTrelloConfig, MaturityNotesDialog, NoteStatus } from '../components/dialogs/MaturityNotesDialog';
import { MaturityTable } from '../components/MaturityTable/MaturityTable';
import { LoadingAnimation } from '../components/LoadingAnimation';
import { MaturityCategory, MaturityDescription, MaturityDescriptionExtended, MaturityLevel, MaturityScore, MaturityScoreExtended } from '../model/ScaleTypes';
import { useContext, useEffect } from 'react';
import TrelloApiContext from '../integrations/trello/trelloContext';
import { useDataProvider, useGetList, useNotify, useRedirect } from 'react-admin';
import { useScalePermissions, withIdentityChecks } from '../components/withIdentityChecks';
import { flattenGroupBy, groupByReduce } from '../util/ScaleUtils';
import { saveAs } from '../util/GuiUtils';
import dayjs from 'dayjs';
import useBusiness from '../state/useBusiness';
import useMaturityModel from '../state/useMaturityModel';
import ConfettiComponent from '../components/ConfettiComponent';

export type ScoresByCategories = { [key: string]: number };

const MaturityView = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { maturityModel, loading: mmLoading } = useMaturityModel();
  const trello = useContext(TrelloApiContext)!;
  const { businessId } = useParams<{ businessId: string }>()

  const [trelloData, setTrelloData] = React.useState<BusinessTrelloConfig | null>(null);
  const [modelData, setModelData] = React.useState<ScoresByCategories>({});
  const [updatedAt, setUpdatedAt] = React.useState<number>(0);
  const [showFocused, setShowFocused] = React.useState<boolean>(true);

  const [hiddenLevels, setHiddenLevels] = React.useState<number[]>([]);

  const [partyOn, setParty] = React.useState(false);

  const [categories, setCategories] = React.useState<MaturityCategory[]>([]);
  const [levels, setLevels] = React.useState<MaturityLevel[]>([]);
  const [descriptions, setDescriptions] = React.useState<MaturityDescriptionExtended[]>([]);

  const [chosenCategoryLevel, setChosenCategoryLevel] = React.useState<null | MaturityDescriptionExtended>(null);
  const [chosenScore, setChosenScore] = React.useState<MaturityScoreExtended>();

  /**
   * These are used for the dialog 
   */
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const handleCloseDialog = () => { setDialogOpen(false); }

  const { data: maturityScoreData, isLoading: isLoadingScoreData, refetch: reloadScoreData } = useGetList(
    "scale_maturityScores",
    // CREATED AT TELLS THE TIME WHEN THE LAST SCORE UPDATE WAS
    { pagination: { page: 1, perPage: 1000 }, sort: { field: "createdAt", order: "DESC" }, filter: { businessId } }
  );

  // @ATTENTION currently breaks if the number of descriptions increases past over 10000.
  const { data: descriptionData, isLoading: isLoadingDescriptions, error } = useGetList("scale_maturityDescription",
    { pagination: { page: 1, perPage: 10000 } });

  const { business, isLoading: isLoadingBusiness } = useBusiness(businessId!);

  const [dataUpdated, setDataUpdated] = React.useState<Boolean>(false);
  const dataProvider = useDataProvider();

  const { permissions, isLoading: permissionsLoading } = useScalePermissions();

  const isLoading = isLoadingBusiness || mmLoading || isLoadingDescriptions || permissionsLoading || isLoadingScoreData;

  const scoreArray = maturityScoreData ? maturityScoreData.map(score => score as MaturityScore) : [];
  // this works because the data is already sorted.
  const latestScores: MaturityScore[] = flattenGroupBy(
    groupByReduce(scoreArray, "maturityCategoryId"),
    values => values[0]
  );


  const updateModelForCategoryAndLevel = (state: ScoresByCategories, maturityCategoryId: string, level: number, cats: MaturityCategory[]) => {
    state[maturityCategoryId] = level;
    return state;
  }

  React.useEffect(() => {
    if (isLoading) { return; }
    console.log("Loading data finished, populating state...");

    if (!maturityModel) {
      console.error("More than one maturity model");
      return;
    }

    if (!business) {
      console.error("no business");
      return;
    }


    let lvls = maturityModel.levels.map(level => level.level).sort((levA, levB) => levA.level - levB.level).reverse();
    let cats = maturityModel.categories!.map(cat => cat.category).sort((catA, catB) => catA.order - catB.order);
    const descs = descriptionData!.map(dd => {
      const maturityDescription = dd as MaturityDescription;
      const description: MaturityDescriptionExtended = {
        ...maturityDescription,
        level: lvls.find(level => level.id === maturityDescription.maturityLevelId) as MaturityLevel,
        category: cats.find(cat => cat.id === maturityDescription.maturityCategoryId) as MaturityCategory
      };
      return description;
    });

    if (showFocused && business.plans) {
      business.plans.sort((planA, planB) => dayjs(planA.targetDate).diff(planB.targetDate));
      console.log("plans", business.plans);
      const ongoingPlan = business.plans.find(plan => dayjs().isBetween(plan.startDate, plan.targetDate));
      if (ongoingPlan) {
        const hiddenLevels = lvls.filter(level => level.level > (ongoingPlan.targetLevel || 10) || level.level <= (ongoingPlan.startLevel || 0)).map(lvl => lvl.level);
        // hide the levels outside of current plan
        setHiddenLevels(hiddenLevels);
        //        lvls = lvls.filter(level => level.level > (ongoingPlan.startLevel || 0) && level.level <= (ongoingPlan.targetLevel || 10));
      }
    }

    setCategories(cats);
    setLevels(lvls);
    setDescriptions(descs);

    const newState = latestScores.reduce((newState, latest) => {
      return updateModelForCategoryAndLevel(newState, latest.maturityCategoryId, latest.score, cats)
    }, modelData)
    setModelData(newState);

    setUpdatedAt(Date.now());
  }, [isLoading, showFocused, business])

  React.useEffect(() => {
    if (dataUpdated && updatedAt !== undefined) {
      reloadScoreData();
      setDataUpdated(false);
    }
  }, [dataUpdated])

  useEffect(() => {
    const constructBusinessTrelloConfig = async () => {
      if (!business || !businessId) return;
      const isTrelloConfigured = await trello.isWorking();
      if (!isTrelloConfigured) { setTrelloData(null); return; }
      if (!business.trelloBoardId || !business.trelloListId) {
        setTrelloData({
          isTrelloConfigured,
          isBusinessConfigured: false,
          businessId
        })
        return;
      }
      const board = await trello.getBoard(business.trelloBoardId);
      const list = await trello.getList(business.trelloListId);
      if (!board || !list) {
        setTrelloData({
          isTrelloConfigured,
          isBusinessConfigured: false,
          businessId
        })
        return;
      }
      setTrelloData({
        isTrelloConfigured,
        isBusinessConfigured: true,
        businessId,
        board,
        list,
      })
    }
    constructBusinessTrelloConfig();
  }, [isLoadingBusiness, business, businessId, trello])

  /* These are needed for showing the content so need to be loaded */
  if (isLoadingDescriptions) { return <LoadingAnimation loadingText='Loading maturity assessment' />; }
  if (!businessId || business === null) return <p>Missing businessId</p>
  if (error) { return <p>ERROR</p>; }

  const currentMaturityScores = latestScores;
  /**
   * Updates the maturity.
   * @param clickedCategoryDescription 
   * @returns 
   */
  const onClickDescriptionCheckbox = (clickedCategoryDescription: MaturityDescriptionExtended) => {

    setDialogOpen(true);
    setChosenCategoryLevel(clickedCategoryDescription);
    // need to make a copy, as adding extra info.
    const scoreStub = currentMaturityScores.find(item => item.maturityCategoryId === clickedCategoryDescription.maturityCategoryId)!;
    const currentScore: MaturityScoreExtended = { ...scoreStub };
    if (currentScore !== undefined && currentScore !== null) {
      currentScore['level'] = levels.find(level => level.level === currentScore.score);
      currentScore['category'] = categories.find(cat => cat.id === currentScore.maturityCategoryId);
    }
    setChosenScore(currentScore);
  }

  const maturityNoteUpdater = async (noteToUpdate: MaturityScore) => {
    dataProvider.update(
      'scale_maturityScores',
      {
        id: noteToUpdate.id,
        data: {
          id: noteToUpdate.id,
          notes: noteToUpdate.notes
        }, previousData: {
          id: noteToUpdate.id,
        }
      }
    )
      .then(() => {
        notify("pos.maturity.commentUpdated");
        setDataUpdated(true);
      })
      .catch(() => notify("pos.maturity.error", { type: "error" }));
  }

  const maturityNoteDeleter = async (noteToDelete: MaturityScore) => {
    dataProvider.delete(
      'scale_maturityScores',
      { id: noteToDelete.id }
    )
      .then(() => {
        notify("pos.maturity.commentUpdated");
        setDataUpdated(true);
      })
      .catch(() => notify("pos.maturity.error", { type: "error" }));
  }

  const onSaveNote = async (maturityDescription: MaturityDescriptionExtended, status: NoteStatus, notesText: string, makeTrelloCard: boolean) => {
    if (!chosenCategoryLevel) return;

    const scoreLevel = status === NoteStatus.RESOLVED ? maturityDescription.level.level : maturityDescription.level.level - 1;
    const notesLevel = maturityDescription.level.level;

    setModelData(state => updateModelForCategoryAndLevel(state, maturityDescription.maturityCategoryId, scoreLevel, categories));

    const payloadData: Partial<MaturityScore> = {
      score: scoreLevel,
      notesScore: notesLevel,
      notes: notesText
    }

    if (makeTrelloCard) {
      const title = `${maturityDescription.category.name} - Level ${maturityDescription.level.level}: ${maturityDescription.level.name}`;
      try {
        const card = await trello.createCard("570e19707028919e6864302f", title, notesText);
        console.log("Card Created", card);
        payloadData.trelloCardId = card.id;
      } catch (e) {
        console.error(e);
      }
    }

    console.log("Saving new maturityScore");
    dataProvider.create(
      'scale_maturityScores',
      {
        data: {
          ...payloadData,
          maturityCategoryId: chosenCategoryLevel.maturityCategoryId,
          businessId,
        }
      }
    )
      .then(() => {
        notify("pos.maturity.saved");
        setDataUpdated(true);
        if (status === NoteStatus.RESOLVED) {
          setParty(true);
        }
      })
      .catch(() => notify("pos.maturity.error"));

    setDialogOpen(false);
  }

  //@TODO Clean up this and the below one to own file.
  const exportMaturityTableAsPng = () => {
    var element = document.getElementById('maturityTable')
    if (element === null) return;
    htmlToImage.toPng(element).then(function (dataUrl) { saveAs(dataUrl, 'export.png') });
  }

  const redirectToBusinessInfo = () => { redirect('show', '/api_business', businessId) };

  return <div id="maturityTableParty">
    <ConfettiComponent partyOn={partyOn} setParty={setParty} />
    <MaturityNotesDialog
      business={business}
      onSaveNote={onSaveNote}
      onMaturityNoteUpdate={maturityNoteUpdater}
      onMaturityNoteDelete={maturityNoteDeleter}
      isDialogOpen={isDialogOpen}
      onCloseDialog={handleCloseDialog}
      maturityDescription={chosenCategoryLevel}
      latestMaturityScore={chosenScore}
      trelloData={trelloData}
      maturityScoreArray={scoreArray} />
    <MaturityTable
      categories={categories}
      levels={levels}
      hiddenLevels={hiddenLevels}
      descriptions={descriptions}
      onClickBack={redirectToBusinessInfo}
      onClickExport={exportMaturityTableAsPng}
      modelData={modelData}
      onClickDescriptionCheckbox={onClickDescriptionCheckbox}
      updatedAt={updatedAt}
      freemium={permissions.isFreemium}
    />
  </div>
}

export default withIdentityChecks(MaturityView);