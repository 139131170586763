import React from 'react';
import { Card, CardContent, Alert, CardActions, CardHeader, Chip, Link } from '@mui/material';
import { Business, MaturityCategory, MaturityLevel, MaturityMilestone, MaturityModel, Plan } from '../../model/ScaleTypes';
import { Button, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import SortableTable, { Column } from '../common/SortableTable';
import useMaturityModel from '../../state/useMaturityModel';
import { LoadingAnimation } from '../LoadingAnimation';
import useBusinessHistoryScores from '../../state/useBusinessHistoryScores';
import { groupByReduce } from '../../util/ScaleUtils';
import dayjs from '../../configuration/configuredDayjs';
import { makeStyles } from 'tss-react/mui';


export interface PlanCardProps {
  business: Business,
  plans?: Plan[], // Array of plans
}

const useStyles = makeStyles()((theme) => ({
  linkStyle: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export function createDisplayNameFromPlanSelectionValue(selectionValue: string, maturityModel: MaturityModel): { name: string, level: number } {
  const delimPosition = selectionValue.indexOf('-');
  const value = Number(selectionValue.substring(0, delimPosition));
  const type = selectionValue.substring(delimPosition + 1)

  var selectionObject: MaturityLevel | MaturityMilestone | undefined;
  switch (type) {
    case 'milestone':
      selectionObject = maturityModel?.milestones?.find(milestone => milestone.level === value);
      break;
    case 'level':
      selectionObject = maturityModel?.levels?.find(level => level.level.level === value)?.level
      break;
  }
  if (selectionObject) return selectionObject;
  return { name: 'unknown', level: 0 };
}

const MilestonePlanCard: React.FC<PlanCardProps> = ({
  business,
  plans }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { classes } = useStyles();
  const { maturityModel, loading } = useMaturityModel();
  const { historyScoreData, isHistoryScoresLoading, historyScoreError } = useBusinessHistoryScores(business.businessId);

  if (loading || isHistoryScoresLoading) return <LoadingAnimation />
  if (!maturityModel) return <Alert severity="error">{translate('errors.maturity_model_missing')}</Alert>



  const lookupById = groupByReduce(historyScoreData, "maturityCategoryId");

  // @TODO sort the data!
  const categories: MaturityCategory[] = maturityModel.categories.map(cat => cat.category)
    .filter(cat => lookupById[cat.id] && lookupById[cat.id].find(val => val.score));

  //  if (categories.length === 0) return <Alert severity="warning">{translate('errors.assessment_missing')}</Alert>;
  const categoryScores: (number | undefined)[] = categories.map(cat => lookupById[cat.id] ? lookupById[cat.id][0]?.score : undefined);


  const columnStyle = { maxWidth: "180px", minWidth: "50px", padding: "2px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" };

  const columns: Column<Plan>[] = [
    { id: 'name', header: 'Milestone Name', style: columnStyle, format: (value, item) => (<Link className={classes.linkStyle} onClick={() => navigate(`/scale_plan/${item.id}`)}>{item.name}</Link>) },
    { id: 'targetDate', header: 'Target Date', style: columnStyle },
    {
      id: 'targetSelection', header: 'Target', style: columnStyle,
      format: (value, plan) => {
        const item = createDisplayNameFromPlanSelectionValue(value, maturityModel);
        return <Chip label={item.name} />
      }
    },
    {
      id: 'businessId', header: 'Completion', style: columnStyle,
      format: (value, plan) => {
        if (plan.targetLevel === null) return "N/A";
        const startLevel: number = plan.startLevel || 0;
        const levelDiff = plan.targetLevel - startLevel;

        const totalLevelsToComplete = (categoryScores.length * levelDiff);

        var levelsCompleted = 0;
        // the levels which are even below the start level are considered to be at start level.
        // the levels which are above the target level are considered to be at the target level.
        categoryScores.forEach(currentScore => {
          levelsCompleted += Math.min(Math.max(currentScore || 0, startLevel), plan.targetLevel || 0) - startLevel
        });
        return levelsCompleted + "/" + totalLevelsToComplete + (levelsCompleted > 0 && levelsCompleted === totalLevelsToComplete ? " 🎉" : "");
      }
    },
    {
      id: 'id', header: '', style: columnStyle,
      format: (value, plan) => (<Button label="actions.generic_edit" type="submit" color="primary" variant='outlined' onClick={() => navigate(`/scale_plan/${plan.id}`)} />
      )
    }
  ];

  const rowFormatter = (item: Plan): React.CSSProperties => {

    if (dayjs().isAfter(item.targetDate)) return {
      backgroundColor: "whitesmoke",
      fontStyle: "italic",
      padding: "1px"
    }
    return {
      padding: "1px"
    }
  }

  return (
    <Card>
      <CardHeader title={translate('milestone.card_header')} />
      <CardContent>
        {plans && <SortableTable data={plans} columns={columns} rowFormatter={rowFormatter} defaultSortColumn='targetDate' />}
        {/*
        <List>
          {plans?.map((plan, index) => (<>
            <ListItem key={plan.id}>
              <ListItemText
                primary={plan.name ? plan.name : plan.targetMilestone ? plan.targetMilestone.name : plan.targetDate}
                secondary={plan.name || plan.targetMilestone ? <>Target date: {plan.targetDate}</> : plan.startDate}
              />
              <Button label="actions.generic_edit" type="submit" color="primary" variant='outlined' onClick={() => navigate(`/scale_plan/${plan.id}`)} />
            </ListItem>
            {index !== plans.length - 1 && <Divider />}
          </>
          ))}
        </List>
          */ }
      </CardContent>
      <CardActions>
        <Button label="actions.milestone_add" type="submit" color="primary" variant='outlined' onClick={() => navigate(`/scale_plan/create?businessId=${business.id}`)} />
      </CardActions>
    </Card>
  );
};

export default MilestonePlanCard;
