import { DateInput, SelectInput, SimpleForm, TextInput, required, useTranslate } from "react-admin";
import useMaturityModel from "../../state/useMaturityModel";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import { Alert, Box, Grid, Typography } from "@mui/material";
import { Plan } from "../../model/ScaleTypes";
import { extractNumber } from "../../util/ScaleUtils";

export const REMOVE_BUSINESS_CASE_ATTRIBUTE = 'removeBusinessCase';

export function transformPlan(data: Plan): Plan {
  data.startLevel = extractNumber(data.startSelection)
  data.targetLevel = extractNumber(data.targetSelection)
  return data;
}

export const PlanForm = ({ ...props }) => {
  const translate = useTranslate();
  const { maturityModel, loading } = useMaturityModel();

  if (loading) return <LoadingAnimation loadingText={translate('generic.loading', { content: 'Maturity Model' })} />
  if (!maturityModel) return <Alert severity="error">{translate('errors.maturity_model_missing')}</Alert>

  const choices: any[] = [];
  maturityModel.milestones!.forEach(milestone => {
    choices.push(
      {
        id: milestone.level + "-milestone",
        key: milestone.level + "-milestone",
        level: milestone.level,
        name: "★ Milestone: " + milestone.name,
      });
  });

  maturityModel.levels.forEach(level => {
    choices.push({
      key: level.level.level + "-level",
      id: level.level.level + "-level",
      level: level.level.level,
      name: level.level.level + ' - ' + level.level.name,
    })
  })

  choices.sort((a, b) => a.level - b.level)

  return <SimpleForm {...props}>
    <Grid container width={{ xs: '100%', xl: 800 }}>
      <Grid item xs={12} md={8}>
        <TextInput fullWidth source="name" validate={required()} />
        <TextInput fullWidth rows={4} source="description" />
        <Grid container>
          <Grid item xs={6}>
            <Box padding={2} borderRight={1} borderColor="grey.300">
              <DateInput source="startDate" fullWidth validate={required()} />
              <Typography variant="caption" gutterBottom>{translate('milestone.startSelectionTip')}</Typography>
              <SelectInput fullWidth source="startSelection" choices={choices} label="milestone.startSelection">
                <SelectInput optionText="name" />
              </SelectInput>
            </Box>

          </Grid>
          <Grid item xs={6}>
            <Box padding={2}>
              <DateInput source="targetDate" fullWidth validate={required()} />
              <Typography variant="caption" gutterBottom>{translate('milestone.targetSelectionTip')}</Typography>
              <SelectInput fullWidth source="targetSelection" choices={choices} label="milestone.targetSelection" validate={required()}>
                <SelectInput optionText="name" />
              </SelectInput>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </SimpleForm>

};