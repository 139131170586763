import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    generic: {
        loading: 'Loading %{content} data...',
        edit: 'Edit Information',
        created: '%{itemType} created!',
        updated: '%{itemType} updated!',
        deleted: '%{itemType} deleted successfully!',
    },
    warnings: {
        delete: {
            project_name_not_matching: 'Project name does not match.'
        },
    },
    errors: {
        empty: 'Cannot be empty.',
        missing: 'No %{itemType} found!',
        loading: 'Error loading information, please contact the support via the bottom right corner.',
        maturity_model_missing: 'Maturity model is not available, please contact the support via the bottom right corner.'
    },
    actions: {
        generic_edit: 'Edit',
        open_assessment: 'Open Maturity Assessment',
        edit_information: 'Edit Project Information',
        open_report: 'Open Report',
        open_report_template_missing: 'Open Report [no template]',
        milestone_add: 'Add milestone plan'
    },
    business: {
        label: 'Project',
        name: 'Name',
        status: 'Status',
        description: 'General Description',
        problemDescription: 'Customer (Stakeholder) Value',
        businessBenefits: 'Benefits for our Organization',
        organizationUnit: 'Organization Unit',
        stakeholders: 'Who is involved',
        score: 'Score',
        last_updated: 'Last Updated',
        budget: 'Budget',
        create: 'Create a new project',
        updated: 'Project updated!',
        createReport: 'Create Report',
        createReport_template_missing: 'Create Report [no template]',
        tags: 'Themes',
        list: {
            title: "Project List"
        },
        assessment_missing: 'Data will be shown after first maturity assessment.'
    },
    milestone: {
        card_header: 'Project Milestones',
        empty: 'No milestones added',
        startSelection: 'Completed level / gate at start',
        targetSelection: 'Target level / gate',
        startSelectionTip: 'This is what has already been completed at start. Leave it empty, if nothing has been completed yet.',
        targetSelectionTip: 'This is the level of maturity or a gate that you aim to reach with this milestone.'
    },
    menu: {
        settings: "Settings",
        dashboard: 'Dashboard',
        businesses: 'Projects',
        integrations: 'Integrations',
        organizationUnits: 'Org. Units',
        reports: 'Reports',
        maturityModel: 'Maturity Model',
    },
    pos: {
        businessShow: {
            maturityScore: "Maturity Score",
            currentMaturity: "Current Maturity",
            maturityHistory: "Maturity History",
            projectInformation: "Project Information"
        },
        maturity: {
            saved: 'Notes saved!',
            commentUpdated: 'Note comment updated!',
            commentRemoved: 'Note comment removed!',
            error: 'Error updating maturity.'
        },
        earlyInsights: {
            saved: 'Insight saved!'
        },
        usermenu: {
            inviteUser: 'Invite user',
            subscription: 'Manage Subscription',
            terms: 'Terms & Conditions',
            logout: 'Logout',
        },
        configuration: 'Configuration',
        profile: 'User profile',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'dark'
        },
        sum: {
            businessPotential: 'Total Potential',
            budget: 'Total Budget',
            count: 'Number of projects',
            weightedPotential: 'Weighted potential',
            score: 'Total Maturity',
        },
        definitions: {
            businessPotential: "Third year revenue after product market fit.",
            budget: "Running yearly budget.",
            count: "",
            score: "Maturity Score, shows the maturity of based on achieved evidence and evaluation of the levels.",
            weightedPotential: "Confidence weighted potential. The maturity score is used to adjust the total potential for the business case. The calculation is 'Business Potential' * 'Maturity Score' / 'Max Maturity Score'.",
            horizonDistribution: "Percentage of projects in different horizons (H1/H2/H3).",
            h1definition: "Maintain and defend core business (1 - 2 years)",
            h2definition: "Nurture emerging business (3 - 5 years)",
            h3definition: "Create genuinely new - possibly disruptive - business (5+ years)",
            pipelineProjects: "Number of projects in different pipeline stages.",
            maturityScore: "Average score for your previous maturity assessment. Maturity scale: 0 - %{levels}",
            currentMaturity: "Maturity assessment scores by category. Dots indicate the previous assessment results.",
            maturityHistory: "Progress overtime including projected future velocity. Green shows the maximum velocity based on the historic progress where as red means the minimum velocity.",
            automaticPriorityList: "Algorithm explained: 1. Prioritizes the gaps first 2. Next organises the objectives based on whether the objective is about collecting or summarising information (\"Double Diamond\" design principle).",
            portfolioBubbleChart: "This is a bubble chart visualisation of your growth portfolio. Project maturity is tracked on X axis whereas Y axis represents the business potential. Size of the bubble is the budget allocated for the project.",

        }
    },
    resources:
    {
        cases: {
            tabs: {
                information: "Case Information",
            }
        },
        definitions: {
            businessPotential: "Third year revenue after product market fit"
        }
    },
};

export default customEnglishMessages;