import { TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Typography, useTheme, Chip, Tooltip } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { BusinessExtended, MaturityModel, defaultMaturityModel } from '../model/ScaleTypes';
import { groupByReduceFunction } from '../util/ScaleUtils';
import { redGreenScaleBackgroundColor, redGreenScaleTextColor } from './Colors';

export interface ProjectsInPhasesProps {
  maturityModel?: MaturityModel;
  maxItemsToList?: number;
  /**
  * Scores for each data
  */
  data: BusinessExtended[];
}

interface LevelInfo {
  level: number,
  name: string,
  projects: BusinessExtended[]
};

interface PhaseInfo {
  order: number,
  name: string,
  levelInfos: LevelInfo[]
}

/**
 * Primary UI component for user interaction
 */
export const ProjectsInPhases: React.FC<ProjectsInPhasesProps> = ({
  maturityModel = defaultMaturityModel,
  maxItemsToList = 3,
  data,
  ...props
}) => {

  const theme = useTheme();
  const [isSmallCell, setIsSmallCell] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 10 * 80) {
      setIsSmallCell(true);
    } else {
      setIsSmallCell(false);
    }
  };

  useEffect(() => {
    handleResize(); // Call once initially
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // @TODO level indexing should be more intuintive, now businesses in 0..1 are on level 1, etc.
  // Map the businessess to each level, also 0 level for 'idea selection', otherwise map to the level (level is always the next larger integer)
  const businessesByLevel = groupByReduceFunction(data, (item: BusinessExtended) => { return item.score ? Math.floor(item.score) + 1 : 'NAN' });

  const infoPerLevel: LevelInfo[] = maturityModel.levels.map(item => {
    return {
      level: item.level.level,
      name: item.level.name,
      projects: businessesByLevel[item.level.level] ? businessesByLevel[item.level.level] : []
    }
  });

  //@TODO Maybe this should be pushed into database, although that requires some work with the model as there are no descriptions for level 0.
  infoPerLevel.push({
    level: 0,
    name: "Opportunity selection", // @TODO would "Not evaluated" be better?
    projects: businessesByLevel['NAN'] ? businessesByLevel['NAN'] : []
  });

  const infoPerPhase: PhaseInfo[] = maturityModel.phases.map(phase => {
    return {
      order: phase.firstLevel,
      name: phase.name,
      levelInfos: infoPerLevel.filter(levelInfo =>
        (levelInfo.level > phase.firstLevel || phase.firstLevel === 0) // if phase firstLevel 0 it includes all levels less then or equal last level, otherwise exclusive of the level on first
        && levelInfo.level <= phase.lastLevel)
    }
  });

  // SORT both phases and levels in them
  infoPerPhase.sort((a, b) => (a.order > b.order) ? 1 : -1);
  infoPerPhase.forEach(info => info.levelInfos.sort((a, b) => (a.level > b.level) ? 1 : -1));

  function numberOrList(projects: BusinessExtended[]) {
    if (projects?.length > maxItemsToList || projects?.length === 0) return <Typography style={{ fontSize: theme.typography.fontSize * 1.5, fontWeight: 'bold' }}>{projects.length}</Typography>
    return <>
      {projects?.map((item, index) => <Typography key={index} noWrap={true} style={{ border: '1px black', fontSize: '8px', backgroundColor: index % 2 ? '#ddd' : '#bbb' }}>{item.name}</Typography>)}
    </>
  }

  return (
    <div style={{ textAlign: 'center', overflowX: 'auto' }}>
      <TableContainer id="priorityList">
        <Table size="small" style={{ boxShadow: 'none', tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {infoPerPhase.map((phase, ippIndex) => <Fragment key={ippIndex}>
                {phase.levelInfos.map((item: any, levelIndex) =>
                  <TableCell key={levelIndex} align={'center'} style={{ border: '0px' }}>
                    {numberOrList(item.projects)}
                  </TableCell>
                )}
              </Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {infoPerPhase.map((phase, ippIndex) => <Fragment key={ippIndex}>
                {phase.levelInfos.map((level, levelIndex) =>
                  <TableCell key={levelIndex} align={'center'}>
                    {isSmallCell ?
                      <Tooltip title={level.name}>
                        <Chip label={level.level} style={{
                          color: redGreenScaleTextColor((level.level || 0) / maturityModel.maxLevel),
                          backgroundColor: redGreenScaleBackgroundColor((level.level || 0) / maturityModel.maxLevel)
                        }} />
                      </Tooltip>
                      :
                      <Typography overflow='hidden' textOverflow='ellipsis'>{level.name}</Typography>
                    }
                  </TableCell>
                )}
              </Fragment>
              )}
            </TableRow>
            <TableRow>
              {infoPerPhase.map((item, ippIndex) =>
                <TableCell key={ippIndex} style={{ border: '1px solid #ddd' }} colSpan={item.levelInfos.length} align={'center'} ><Typography>{item.name.replaceAll('<br>', ' ')}</Typography></TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};