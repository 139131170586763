import React, { Fragment } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { MaturityDescriptionExtended } from "../../model/ScaleTypes";
import { toParagraphs } from "../../util/GuiUtils";
import { RESOLVED_BRIGHT, RESOLVED_STANDARD } from '../Colors';

export interface MaturityDesciptionCellProps {
  key: React.Key;
  maturityDescription: MaturityDescriptionExtended;
  state: 'checked' | 'unchecked' | 'disabled';
  onClickDescriptionCheckbox: (clickedCategoryDescription: MaturityDescriptionExtended) => void;
  freemium: boolean;
  updatedAt: number;
}

export const MaturityDescriptionTableCell: React.FC<MaturityDesciptionCellProps> = ({ updatedAt, freemium, maturityDescription, state, onClickDescriptionCheckbox }) => {
  const category = maturityDescription.category;
  const level = maturityDescription.level;

  const freemiumDisabled = (freemium && (level.level > 3 || category.order > 5));
  const isDisabled = state === 'disabled' || freemiumDisabled;

  const toolTipDescription =
    freemiumDisabled ? "FREEMIUM INCLUDES FIRST 3 LEVELS & 5 CATEGORIES" :
      toParagraphs(
        maturityDescription.description +
        (maturityDescription.examples?.length > 0 ? "\nExamples\n" + maturityDescription.examples : ""));

  return (<Fragment key={category.id}>
    { /** checkbox part */}
    <TableCell onClick={() => !isDisabled && onClickDescriptionCheckbox(maturityDescription)}
      style={{
        backgroundColor: state === 'checked' ? RESOLVED_STANDARD : '#f4f4f4',
        padding: '0px'
      }} >
      <Checkbox
        checked={state === 'checked'}
        disabled={isDisabled}
        data-ts={updatedAt} />
    </TableCell>
    { /** content part */}
    <TableCell onClick={() => !isDisabled && onClickDescriptionCheckbox(maturityDescription)} className={category.name === "Financial Model" ? "fin_" + level.level : "" || category.name === "Team Composition" ? "team_" + level.level : ""}
      style={
        state === 'checked' ? { backgroundColor: RESOLVED_BRIGHT } :
          // Freemium
          (freemiumDisabled) ? { backgroundColor: '#f4f4f4' } : {}
      }
    >
      <p style={{ fontSize: '0.85em' }}>{maturityDescription.name}
        {maturityDescription.description?.length > 0 ?
          <Tooltip title={toolTipDescription} arrow>
            <InfoIcon style={{ transform: "scale(0.5)", padding: -3, margin: -3, verticalAlign: "bottom", color: "orange" }} fontSize="small" />
          </Tooltip>
          : ""}
      </p>
    </TableCell>
  </Fragment>)

}