import { InMemoryCache } from "@apollo/client"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DataProvider } from "react-admin"
import { ApolloClient } from "@apollo/client"

export const providerSlice = createSlice({
  name: 'provider',
  initialState: {
    cache: undefined as InMemoryCache | undefined,
    apollo: undefined as ApolloClient<any> | undefined,
    dataProvider: undefined as DataProvider|undefined
  },
  reducers: {
    initApollo(state, { payload: { cache, apollo }}: PayloadAction<{ apollo: ApolloClient<any>, cache: InMemoryCache}>) {
      console.log("ProviderReducer: initApollo", cache, apollo)
      state.cache = cache
      // @ts-ignore-error
      state.apollo = apollo
    },
    initDataProvider(state, { payload: dataProvider }: PayloadAction<DataProvider|undefined>) {
      console.log("ProviderReducer: initDataProvider", dataProvider)
      if (dataProvider === undefined) {
        state.cache?.reset()
        state.dataProvider = undefined
      } else {
        state.dataProvider = dataProvider
      }
    }
  }
})

export const {
  initApollo,
  initDataProvider
} = providerSlice.actions