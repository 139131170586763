import { ApolloClient, ApolloClientOptions, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

import { all, put, select } from "redux-saga/effects";
import { BuildFields, buildFields, FetchType } from 'ra-data-hasura';
import gql from 'graphql-tag';
import { initApollo } from "./ProviderReducer";
import config from "../../configuration/authConfig";
import { StateType } from "../Store";

import { onError } from "@apollo/client/link/error";
import { isTokenExpired } from "../../MainWithAuth";
import { PLANS_GQL } from "../../model/ScaleTypes";
import * as features from "../../configuration/featureFlags";

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    console.log("QLerror", graphQLErrors)
    for (let err of graphQLErrors) {
      // Check if the error is related to an expired token
      if (err.extensions?.code === "invalid-jwt") {
        console.log("JWT token expiry!!!");
        // Handle token expiry (e.g., refresh token or redirect to login)
      }
    }
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    // Handle network errors as needed
  }
});

function* initApolloWorker() {
  const {
    organization,
    cache
  } = (yield select((state: StateType) => ({
    organization: state.login.organization,
    cache: state.provider.cache
  }))) as { organization: string | undefined, cache: InMemoryCache }

  const httpLink = createHttpLink({
    uri: `${config.hasuraUrl}v1/graphql`,
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('scale_token');
    if (token && isTokenExpired(token)) {
      console.log("I have an invalid JWT token, but I am trying to query.", token)
    }

    return {
      headers: {  
        ...headers,
        ...(organization !== undefined) && { 'x-hasura-organizationx-id': organization },
        'content-type': 'application/json',
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  })

  console.log('initApolloQuery');
  const newCache = cache ?? new InMemoryCache()

  const apolloOptions: ApolloClientOptions<InMemoryCache> = {
    link: errorLink.concat(authLink).concat(httpLink),
    // @ts-ignore
    cache: newCache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      }
    }
  }
  console.log(`ProviderSagas: initializing apollo for '${organization}'`, apolloOptions);
  const apollo = new ApolloClient(apolloOptions);

  yield put(initApollo({ apollo, cache }))
}


/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst: any) => {
  return queryAst.definitions[0].selectionSet.selections;
};

// Define the additional fields that we want.
const EXTENDED_MATURITY_MODEL = gql`
{
  phases {
    id
    lastLevel
    firstLevel
    name
    areaColor
    areaTitle
  }
  milestones {
    id
    shortName
    name
    description
    level
    color
    line
    lineText
  }
  categories {
    category {
      id
      order
      name
    }
  }
  levels {
    level {
      id
      level
      name
    }
    score
  }
  levels_aggregate {
    aggregate { max {score} }
  }
}
`;

const BUSINESS_FILES = gql`
{
  files {
    name
    url
  }
}
`

const EXTENDED_BUSINESS = gql`
{
  insights {
    id
    risk
    impact
  }
  organizationUnit {
    name
    organization {
      id
      friendlyId
    }
  }
  plans ${PLANS_GQL}
}
`;

const businessFileFields = extractFieldsFromQuery(BUSINESS_FILES)
const extendedMaturityModelFields = extractFieldsFromQuery(EXTENDED_MATURITY_MODEL)
const extendedBusinessFields = extractFieldsFromQuery(EXTENDED_BUSINESS)

export const customBuildFields: BuildFields = (type: any, fetchType?: FetchType | undefined) => {
  const resourceName = type.name;

  // First take the default fields (all, but no related or nested).
  const defaultFields = buildFields(type, fetchType);

  // console.log("resource name: " + resourceName,"fetchType", fetchType );
  // console.log("defaultFields", defaultFields);

  if (resourceName === 'api_maturityModelByOrganization' && fetchType === 'GET_LIST') {
    defaultFields.push(...extendedMaturityModelFields);
  }

  else if (features.storageProxyEnabled === true
           && (resourceName === 'api_business' || resourceName === 'api_history_business')
           && fetchType === FetchType.GET_ONE) {
    defaultFields.push(...businessFileFields)
    defaultFields.push(...extendedBusinessFields)
  }

  else if (resourceName === 'api_business' || resourceName === 'api_history_business' && fetchType === 'GET_LIST') {
    defaultFields.push(...extendedBusinessFields);
  }

  // Extend other queries for other resources/fetchTypes here...

  return defaultFields;
};
/*
function* initDataProviderWorker() {
  const {
    client
  } = (yield select((state: StateType) => ({
    client: state.provider.apollo
  }))) as { client: ApolloClient<any>, }
  console.log(`ProviderSagas: initDataProviderWorker(): initializing a new data provider`, client);
  const dataProvider: DataProvider = yield call(buildHasuraProvider, { client }, { buildFields: customBuildFields })
  yield put(initDataProvider(dataProvider))
}
*/
export function* providerSagas() {
  yield all([
    initApolloWorker(),
    //    takeEvery('login/setLoggedIn', initDataProviderWorker)
  ])
}