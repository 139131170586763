import React, { useState, ReactNode } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useTranslate } from 'react-admin';
import { CARD_LIGHT } from '../Colors';

function getSorting<T>(order: 'asc' | 'desc', orderBy: keyof T): (a: T, b: T) => number {
  return order === 'asc'
    ? (a, b) => compare(a[orderBy], b[orderBy])
    : (a, b) => -compare(a[orderBy], b[orderBy]);
}

function compare(a: any, b: any): number {
  if (!a) return -1;
  if (!b) return 1;
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  } else if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  } else {
    return 0;
  }
}

export type Column<T> = {
  id: keyof T;
  header: string | ReactNode;
  style?: React.CSSProperties;
  format?: (value: any, item: T) => ReactNode;
  disableSorting?: boolean
  width?: string;
};

type SortableTableProps<T> = {
  columns: Column<T>[];
  data: T[];
  defaultSortColumn?: keyof T;
  defaultSortDirection?: 'asc' | 'desc';
  rowFormatter?: (row: T) => React.CSSProperties;
};

function SortableTable<T>({ columns, data, defaultSortColumn, defaultSortDirection, rowFormatter }: SortableTableProps<T>) {
  const translate = useTranslate();
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>(defaultSortDirection || 'desc');
  const [orderBy, setOrderBy] = useState<keyof T | null>(defaultSortColumn || null);

  const handleSortRequest = (property: keyof T) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = orderBy
    ? [...(data || [])].sort(getSorting(orderDirection, orderBy))
    : data;

  const makeColumnHeader = (column: Column<T>) => {
    if (column.disableSorting) {
      return typeof column.header === 'string' ? <strong>{translate(column.header, { _: column.header })}</strong> : column.header
    }

    return <TableSortLabel hideSortIcon={true}
      disabled={!!column.disableSorting}
      active={orderBy === column.id}
      direction={orderBy === column.id ? orderDirection : 'asc'}
      onClick={() => handleSortRequest(column.id)}
    >
      {typeof column.header === 'string' ? <strong>{translate(column.header, { _: column.header })}</strong> : column.header}
    </TableSortLabel>
  };


  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell key={column.id as string}>
              {makeColumnHeader(column)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData.map((row, index) => (
          <TableRow key={index} style={rowFormatter ? rowFormatter(row) : undefined}>
            {columns.map(column => (
              <TableCell key={column.id as string} width={column.width} style={column.style}>
                { /* @ts-ignore */}
                {column.format ? column.format(row[column.id], row) : row[column.id]}
              </TableCell>
            ))}
          </TableRow>
        ))}
        {sortedData.length === 0 && <TableRow key={0}>
          <TableCell style={{ textAlign: 'center', fontStyle: 'italic', backgroundColor: CARD_LIGHT }} colSpan={columns.length}>No data to show</TableCell>
        </TableRow>}
      </TableBody>
    </Table >
  );
}

export default SortableTable;