import { Box, Button, Chip, Link, Checkbox } from '@mui/material';
import React, { useState } from 'react';
import { BusinessExtended, defaultMaturityModel } from '../model/ScaleTypes';
import { UsesMaturityModelProps } from './charts/ScaleGraphTypes';
import { CurrencyFormat } from '../configuration/configuration';
import HorizontalBar from './HorizontalBar';
import { makeStyles } from 'tss-react/mui';
import { redGreenScaleBackgroundColor } from './Colors';
import currency from 'currency.js';
import SortableTable, { Column } from './common/SortableTable';
import dayjs from '../configuration/configuredDayjs';

export interface BusinessListProps extends UsesMaturityModelProps {
  businessList: BusinessExtended[],
  onClickListItem: (item: any) => void,
  onCheckboxChange?: (id: string, checked: boolean) => void,
//  onCheckboxSelectAll?: (state: boolean) => void,
  emptyListComponent?: React.ReactNode
}

const useStyles = makeStyles()((theme) => ({
  linkStyle: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const ScoreElement: React.FC<{ value: number, maxValue: number, width: number }> = ({
  value,
  maxValue,
  ...props
}) => {
  const [showButton, setShowButton] = useState<Boolean>(false);
  return <Box height={10}
  //    onMouseEnter={(event) => {!showButton && setShowButton(true) }} 
  //    onMouseLeave={() => { setShowButton(false)}}
  >
    {showButton && <Button style={{ fontSize: "8px", padding: "0px" }} fullWidth>Open Maturity</Button>}
    {!showButton && <HorizontalBar showText
      percentage={(value || 0) / maxValue * 100}
      text={value ? "" + (Math.round(value * 10) / 10) : 'N/A'}
      color={redGreenScaleBackgroundColor((value || 0) / maxValue)}
      {...props}
    />}

  </Box>
}

export const BusinessList: React.FC<BusinessListProps> = ({
  maturityModel = defaultMaturityModel,
  businessList,
  onClickListItem,
  onCheckboxChange, // Pass the prop
//  onCheckboxSelectAll,
  emptyListComponent,
  ...props
}) => {
  const { classes } = useStyles();

  if ((!businessList || businessList.length === 0) && emptyListComponent) return <>{emptyListComponent}</>;

  const columns: Column<BusinessExtended>[] = [
    { id: 'name', header: 'Name', style: { maxWidth: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }, format: (value, item) => (<Link className={classes.linkStyle} onClick={() => onClickListItem(item)}>{item.name}</Link>) },
    { id: 'organizationUnitName', header: 'Org. unit', style: { width: '10%', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } },
    {
      id: 'tags', header: 'business.tags', style: { maxWidth: "120px" },
      format: (value) => (value?.map((v: string, i: number) => <Chip style={{ height: 12 }} key={i} size="small" label={v} />))
    },
    { id: 'status', header: 'Status', style: { whiteSpace: "nowrap" } },
    { id: 'startDate', header: 'Start date', style: { whiteSpace: "nowrap" } },
    {
      id: 'businessPotential', header: 'Business Pot.', style: { textAlign: "right", maxWidth: "100px", overflow: 'hidden', textOverflow: "ellipsis" },
      format: (value) => { return value ? currency(value, CurrencyFormat).format() : "-"; }
    },
    {
      id: 'budget', header: 'Budget', style: { textAlign: "right" },
      format: (value) => { return value ? currency(value, CurrencyFormat).format() : "-"; }
    },
    {
      id: 'score', header: 'Score',
      format: (value) => (<ScoreElement width={50} value={value} maxValue={maturityModel.maxLevel} />)
    },
    {
      id: 'scoreModifiedAt', header: 'Last Assessed', style: { whiteSpace: "nowrap" },
      format: (value) => (value ? dayjs(value).fromNow() : "never")
    }
  ]
  
  // If there is the onCheckboxChange prop, add the checkbox column to the beginning of the columns array
  if (onCheckboxChange) columns.unshift(
    {
      id: 'id', disableSorting: true, header: '', style: { maxWidth: '0px' },
      //      id: 'id', disableSorting: true, header: <Checkbox style={{ padding: 0 }} onChange={(event) => onCheckboxSelectAll && onCheckboxSelectAll(event.target.checked)} />, style: { maxWidth: '0px' },
      format: (value, item) => (<Checkbox style={{ padding: 0 }} onChange={(event) => onCheckboxChange(value, event.target.checked)} />)
    },
  )
  return (
    <SortableTable data={businessList} columns={columns} defaultSortColumn='scoreModifiedAt' />
  )





};
