import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  percentage: {
    fontWeight: 'bold',
  },
}));

export const ChangeIndicator = ({ change, changeMode = 'relative' } : { change: number, changeMode?: 'relative' | 'absolute' }) => {
  const { classes } = useStyles();
  const color = change > 0 ? 'green' : change < 0 ? 'red' : 'gray';
  let icon = change > 0 ? <ArrowUpwardIcon style={{ color: 'green' }} /> : change < 0 ? <ArrowDownwardIcon style={{ color: 'red' }} /> : <></>
  const string = change === 0 ? '-' :
    changeMode === 'relative' ? 
      Math.abs(change).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }) : 
      Math.abs(change).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 1});
  return (
    <span className={classes.container}>
      {icon}
      <span className={classes.percentage} style={{ color }}>
        {string}
      </span>
    </span>
  );
};