import React from 'react';
import { MaturityCategory, MaturityMilestone, MaturityModel, MaturityScoreOnly, Plan } from '../../model/ScaleTypes';
import { SCALE_THEME, scaleColors } from '../../layout/theme';
import Plot from 'react-plotly.js'
import Plotly from 'plotly.js';

import { Alert } from '@mui/material';
import { groupByReduce } from '../../util/ScaleUtils';
import { useTranslate } from 'react-admin';
import { MATURITY_CATEGORY_COLORS } from '../Colors';
import { Annotations, Layout, Shape } from 'plotly.js';
import { createDisplayNameFromPlanSelectionValue } from '../cards/PlanCard';
import dayjs from '../../configuration/configuredDayjs';
import { PLAN_STYLE_ONGOING } from './BusinessHistoryGraph';
import { PlotlyChartParams } from './PlotlyCharts';

export interface MaturityChartProps {
  width?: string,
  height?: string,
  /**
   * Maturity Model information
   */
  maturityModel: MaturityModel,
  /**
   * Type of the chart, default bar
   */
  type?: 'bar' | 'radar',
  /**
   * Show average
   */
  showAverage?: boolean,
  /**
   * Scores
   */
  scores: MaturityScoreOnly[],
  /**
   * Current Plans
   */
  plans?: Plan[],
}

export function addPlanToLayout(layout: Partial<Layout>, plan: Plan, maturityModel: MaturityModel, shapeStyle: Partial<Shape> = {}) {
  if (!plan.targetLevel || !plan.targetSelection) return;

  const selection = createDisplayNameFromPlanSelectionValue(plan.targetSelection, maturityModel)

  const hasXAxis = layout.xaxis && layout.xaxis.range;

  const planBox: Partial<Shape> = hasXAxis ?
    {
      ...shapeStyle,
      type: 'rect',
      y0: plan.startLevel || 0,
      y1: plan.targetLevel,
      x0: plan.startDate,
      x1: plan.targetDate,
    } : {
      ...shapeStyle,
      type: 'line',
      y0: plan.targetLevel,
      y1: plan.targetLevel,
      x0: 0,
      x1: 1,
      xref: 'paper',
    }

  const horizontalText: Partial<Annotations> = {
    y: plan.targetLevel,
    yanchor: 'bottom',
    x: hasXAxis ? plan.startDate : 0,
    xref: hasXAxis ? 'x' : 'paper',
    xanchor: 'left',
    text: `<b>Milestone</b>: ${plan.name} [${selection.name}]`,
    font: {
      size: 7,
      color: 'black'
    },
    opacity: 1,
    showarrow: false
  };

  // Vertical text at the targetDate
  const targetVerticalText: Partial<Annotations> = {
    y: plan.startLevel || 0,
    yanchor: 'bottom',
    x: plan.targetDate,
    xanchor: 'left',
    text: `<b>Target Date</b>:<br /> ${plan.name} `,
    font: {
      size: SCALE_THEME.typography.fontSize * 0.5
    },
    showarrow: false,
    textangle: "90"
  };

  layout.shapes!.push(planBox);
  layout.annotations!.push(horizontalText);
  // show the target date text only for the ongoing plan
  if (hasXAxis && dayjs().isBetween(plan.startDate, plan.targetDate)) layout.annotations!.push(targetVerticalText);
}


export function addMaturityMilestoneToLayout(layout: Partial<Layout>, milestone: MaturityMilestone, maturityModel: MaturityModel, opacity: number) {
  const milestoneText: Partial<Annotations> = {
    xref: 'paper',
    y: milestone.level,
    yanchor: 'middle',
    x: 1,
    xanchor: 'left',
    text: milestone.shortName,
    font: {
      size: 10,
      color: '#ffffff'
    },
    bgcolor: milestone.color ? milestone.color : '#ff7f0e',
    opacity: opacity,
    showarrow: false
  };
  const milestoneLine: Partial<Shape> = {
    type: 'line',
    xref: 'paper',
    y0: milestone.level,
    x0: 0,
    y1: milestone.level,
    x1: 1,
    line: {
      color: milestone.color ? milestone.color : 'rgb(255, 0, 0)',
      width: 1,
      dash: milestone.line ? milestone.line : 'dot'
    },
    opacity: opacity
  };
  layout.annotations!.push(milestoneText);
  layout.shapes!.push(milestoneLine)
}

/**
 * Returns data and layout needed for MaturityChart
 * @param param0 
 * @returns 
 * @throws Error business.assessment_missing if there is no assessment data available.
 */
export const createMaturityChartData = ({
  maturityModel,
  scores,
  plans,
  type = 'bar',
  showAverage = true,
}: MaturityChartProps): PlotlyChartParams => {
  const lookupById = groupByReduce(scores, "maturityCategoryId");

  // @TODO sort the data!

  const categories: MaturityCategory[] = maturityModel.categories.map(cat => cat.category)
    .filter(cat => lookupById[cat.id] && lookupById[cat.id].find(val => val.score));

  if (categories.length === 0) {
    return {
      data: [],
      layout: {},
      error: 'business.assessment_missing'
    }
  }
  const categoryNames = categories.map(cat => cat.name);
  const currentValues: (number | undefined)[] = categories.map(cat => lookupById[cat.id] ? lookupById[cat.id][0]?.score : undefined);
  const previousValues: (number | undefined)[] = categories.map(cat => lookupById[cat.id] ? lookupById[cat.id][1]?.score : undefined);
  const colors: (string | undefined)[] = categories.map(cat => MATURITY_CATEGORY_COLORS[cat.order - 1]);

  const maturityBarData: Plotly.Data = {
    x: categoryNames,
    //@ts-ignore
    y: currentValues,
    type: 'bar',
    fill: 'toself',
    marker: {
      color: colors,
      line: {
        color: "black",
        width: 1,
      }
    },
    /*    text: categoryNames,
        textfont: {
          size: 6,
        },*/
    name: '',
    hovertemplate:
      '%{x}: %{y}'
  };

  // radar graph needs the first category twice
  const maturityRadarData: Plotly.Data = {
    // @ts-ignore
    theta: [...maturityBarData.x, maturityBarData.x[0]],
    // @ts-ignore
    r: [...maturityBarData.y, maturityBarData.y[0]],
    type: 'scatterpolar',
    fill: 'toself',
    marker: {
      color: scaleColors.Dark
    },
    customdata: [...categoryNames, categoryNames[0]],
    text: categoryNames,
    textfont: {
      size: 9,
    },
    hovertemplate: '%{customdata} %{r}',
    showlegend: true,
    name: '',
    hoverlabel: {
      namelength: -1,
      align: 'auto'
    }
  };

  const sum = currentValues.reduce((s, v) => s! + Number(v), 0) || 0;
  const avgScore = sum / currentValues.length || 0;

  var layout: Partial<Layout> = {
    showlegend: false,
    width: 500,
    margin: {
      l: 60,
      r: 50,
      b: 100,
      t: 20,
    },
    xaxis: {
      tickangle: -45,
      tickfont: {
        size: SCALE_THEME.typography.fontSize * 0.8
      }
    },
    yaxis: {
      showline: true,
      range: [0, maturityModel.maxLevel],
      title: 'score'
    },
    annotations: [],
    shapes: []
  }

  // TODO: Clean layout and graph building 

  plans?.forEach(plan => {
    if (dayjs().isBetween(plan.startDate, plan.targetDate)) addPlanToLayout(layout, plan, maturityModel, PLAN_STYLE_ONGOING);
  });
  const milestoneOpacity = plans && plans.length > 0 ? 0.2 : 0.7;
  maturityModel.milestones?.forEach(milestone => addMaturityMilestoneToLayout(layout, milestone, maturityModel, milestoneOpacity))

  if (showAverage) {
    const averageText: Partial<Annotations> = {
      xref: 'paper',
      y: avgScore,
      yanchor: 'middle',
      x: 0.5,
      xanchor: 'auto',
      text: 'average',
      yshift: 10,
      font: {
        color: 'rgb(255, 0, 0)',
      },
      showarrow: false,
    };
    const averageLine: Partial<Shape> = {
      type: 'line',
      xref: 'paper',
      y0: avgScore,
      x0: 0,
      y1: avgScore,
      x1: 1,
      line: {
        color: 'rgb(255, 0, 0)',
        width: 1,
        dash: 'dash'
      },
    };
    layout.annotations!.push(averageText);
    layout.shapes!.push(averageLine);
  }

  // Add necessary datas to the plot.
  const datasToDisplay: Plotly.Data[] = [];
  if (type === 'bar') {
    layout = { ...barLayoutFragment, ...layout };
    datasToDisplay.push(maturityBarData);
  } else {
    layout = { ...radarLayoutFragment(maturityModel.maxLevel), ...layout };
    datasToDisplay.push(maturityRadarData);
  }
  if (previousValues) {
    const pastScores: Plotly.Data = {
      x: categoryNames,
      //@ts-ignore
      y: previousValues,
      type: 'scatter',
      mode: 'markers',
      marker: {
        color: scaleColors.Brand,
      },
      name: 'Previous assessment',
      hovertemplate:
        '%{x}: %{y}'
    };
    datasToDisplay.push(pastScores);
  }
  return {
    data: type === 'bar' ? datasToDisplay : [maturityRadarData],
    layout
  };
}


/**
 * Primary UI component for user interaction
 * @deprecated use the PlotlyChart and pass the created parameters there instead
 */
export const MaturityChart: React.FC<MaturityChartProps> = ({
  width,
  height,
  maturityModel,
  scores,
  plans,
  type = 'bar',
  showAverage = true,
  ...props
}) => {
  const translate = useTranslate();
  if (!maturityModel) return <Alert severity='error'>{translate('errors.maturity_model_missing')}</Alert>

  try {
    const plotlyParams = createMaturityChartData({ maturityModel, scores, plans, type, showAverage })
    //@ts-ignore
    return <Plot data={plotlyParams.data}
      layout={plotlyParams.layout}
      style={{ width, height }}
      config={{ displayModeBar: false, responsive: false }} />
  } catch (error) {
    //@ts-ignore
    return <Alert severity='warning'>{translate(error.message)}</Alert>
  }
};


const barLayoutFragment = {
  xaxis: {
    tickangle: -45
  },
  yaxis: {
    range: [0, 20],
  },
}

const radarLayoutFragment = function (maxLevel: number) {
  return {
    polar: {
      radialaxis: {
        visible: true,
        range: [0, maxLevel]
      }
    }
  }
}
/*

export const getPlotlyChartAsPng = async (): Promise<string> => {
  // Create an off-DOM container div for the chart
  const offDomContainer = document.createElement('div');

  // Styling to keep it out of view
  offDomContainer.style.position = 'absolute';
  offDomContainer.style.left = '-9999px';
  
  document.body.appendChild(offDomContainer);

  // Define your data and layout
  const data: Plotly.Data[] = [{
    x: ['giraffes', 'orangutans', 'monkeys'],
    y: [20, 14, 23],
    type: 'bar'
  }];

  const layout = {
    title: 'Plotly Graph'
  };

  // Render the chart
  await Plotly.newPlot(offDomContainer, data, layout);

  try {
    // Generate the PNG data URL
    const dataUrl = await Plotly.toImage(offDomContainer, {
      format: 'png',
      height: 500,
      width: 700
    });

    // Clean up: Remove the off-DOM container
    offDomContainer.remove();

    return dataUrl; // Return the PNG data URL
  } catch (error) {
    console.error('Error generating image:', error);
    
    // Clean up: Remove the off-DOM container
    offDomContainer.remove();

    throw error;
  }
};
*/