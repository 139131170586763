import { CurrencyFormat } from "../configuration/configuration";
import currency from "currency.js";

export const defaultCurrencyFormatter = (value: number) => {
  return currency(value, CurrencyFormat).format();
};

export const formatToPercentage = (num: number): string => {
  return `${(num*100).toFixed(1)}%`;
};
