import { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { MaturityModel, constructMaturityModelFromRecord } from '../model/ScaleTypes';

export default function useMaturityModel() {
  const [maturityModel, setMaturityModel] = useState<MaturityModel | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { data, total, isLoading } = useGetList<MaturityModel>(
    'api_maturityModelByOrganization',
    { pagination: { page: 1, perPage: 10000 }, },
    { staleTime: 2 * 60 * 1000 }
  );

  useEffect(() => {
    if (!isLoading) {
      if (data && total === 1) {
        setMaturityModel(constructMaturityModelFromRecord(data[0]));
      } else {
        console.error(`Failure loading maturity model, received ${total} models.`, data)
        setError(true);
      }
      setLoading(false);
    }
  }, [total, data, isLoading]);
  return { maturityModel, loading, error };
}
