import { reducer } from './State'
import { configureStore, EnhancedStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import { sagas } from "./Sagas"

let sagaMiddleware = createSagaMiddleware()
const middleware = [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware]

// configure redux state
export const store = configureStore({
  reducer,
  middleware
})

export type StateType =
  typeof store extends EnhancedStore<infer I> ? I :
  typeof store

sagaMiddleware.run(sagas)



