import {
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import {
  ArrayField,
  Datagrid,
  ImageField,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { BusinessExtended } from "../../model/ScaleTypes";

type FilesProps = {
  record: BusinessExtended
}

const ProjectFilesCard = ({ record }: FilesProps) => {
  return (
    <Card>
      <CardHeader title='Report Attachments' />
      <CardContent>
        <SimpleShowLayout record={record}>
          <ArrayField source="files" label={false}>
            <Datagrid bulkActionButtons={false}
                      sx={{ '& .RaDatagrid-headerCell': {
                        backgroundColor: "MistyRose",
                        fontWeight: 'bold',
                       }}}>
              <TextField source="name"
                         label="File name" />
              <ImageField source="url"
                          label="Preview"
                          sx={{ '& img': { maxWidth: 100, maxHeight: 40, objectFit: 'contain', align: 'left' } }} />
            </Datagrid>
          </ArrayField>
        </SimpleShowLayout>
      </CardContent>
    </Card>
  )
}

export default ProjectFilesCard
