import { getNestedProperty, toNumber } from "../../util/ScaleUtils";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import InfoIconTooltip from "../InfoIconTooltip";
import { useTranslate } from "react-admin";
import { ChangeIndicator } from "../common/ChangeIndicator";
import { defaultCurrencyFormatter } from "../../util/FormattingUtils";

interface PropertySumStatProps<T> {
  now: T[],
  past?: T[];
  propertyName: string;
  default?: number;
  changeMode?: 'relative' | 'absolute';
  formatter?: (value: number) => any;
}

export const NumberFormat = (value: number) => { return Math.trunc(value * 10) / 10; }

const PropertySumStat = <T,>(props: PropertySumStatProps<T>) => {
  const { propertyName, formatter = defaultCurrencyFormatter, default: defaultValue = 0, now, past, changeMode } = props;
  //@ts-ignore
  const property: keyof T = propertyName;
  const translate = useTranslate();

  const nowSum = now.reduce((sum: number, item: T) => sum + (item ? toNumber(getNestedProperty(item, property) || defaultValue, 0) : 0), 0);
  const pastSum = past ? past.reduce((sum: number, item: T) => sum + (item ? toNumber(getNestedProperty(item, property) || defaultValue, 0) : 0), 0) : 0;
  const change = (nowSum - pastSum);

  const definitionText = translate(`pos.definitions.${String(propertyName)}`);

  return (
    <Card>
      <CardHeader title={translate(`pos.sum.${propertyName}`)}
        action={(definitionText !== "") && <InfoIconTooltip title={definitionText} />}
      />
      <CardContent id="KPIs" style={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h5">{formatter(nowSum)}</Typography>
        {props.past && <ChangeIndicator change={changeMode === 'absolute' ? change : pastSum !== 0 ? change / pastSum : 0} changeMode={changeMode} />}
      </CardContent>
    </Card>
  );
};

interface DiffCardProps {
  title: string,
  toolTipText?: string,
}


export default PropertySumStat;
