import React, { Fragment } from 'react';
import { MaturityModel } from '../model/ScaleTypes';
import { useTranslate } from 'react-admin';
import { Alert, Card, CardContent, CardHeader, Typography } from '@mui/material';
import InfoIconTooltip from './InfoIconTooltip';
import { ChangeIndicator } from './common/ChangeIndicator';

export interface MaturityScoreCardProps {
  pastDailyScores: number[];
  maturityModel?: MaturityModel;
}

export const MaturityScoreCard: React.FC<MaturityScoreCardProps> = ({ pastDailyScores, maturityModel }) => {
  function getLevelName(maturityScore: number): string {
    // TODO: Fix null pointer bug that occurs if current user doesn't have access to particular business case. How to reproduce: open project, change organisation from Auth0, reload page. Level is undefined and throws error.
    return maturityModel?.levels ? maturityModel?.levels.filter((level) => { return level.level.level === Math.floor(Math.min(maturityModel?.maxLevel - 1, maturityScore)) + 1 })[0].level.name : "";
  };
  const translate = useTranslate();
  if (!maturityModel) return <Alert severity='error'>{translate('errors.maturity_model_missing')}</Alert>


  const MaturityScoreCardContent: React.FC = () => {
    if (pastDailyScores.length === 0) return <Alert severity='warning'>{translate('business.assessment_missing')}</Alert>

    const levelName = getLevelName(pastDailyScores[pastDailyScores.length - 1]);
    const score = Math.round(pastDailyScores[pastDailyScores.length - 1] * 10) / 10;
    const change = pastDailyScores.length >= 2 ? pastDailyScores[pastDailyScores.length - 1] - pastDailyScores[pastDailyScores.length - 2] : 0;


    return <Fragment><Typography variant="h2">{score}</Typography>
      {pastDailyScores.length > 1 && <ChangeIndicator change={change} changeMode='absolute' />}
      <Typography variant="h5">{levelName}</Typography>
    </Fragment>
  }

  return (
    <Card>
      <CardHeader
        title={translate('pos.businessShow.maturityScore')}
        action={<InfoIconTooltip title={translate('pos.definitions.maturityScore', { levels: maturityModel?.maxLevel })} />}
      />
      <CardContent style={{ textAlign: 'center', alignItems: 'center' }}>
        <MaturityScoreCardContent />
      </CardContent>
    </Card>
  );
};
