import { DateField, NumberField, SimpleShowLayout, TextField } from 'react-admin';
import { Alert, Card, CardContent, CardHeader } from "@mui/material";
import { CurrencyFormat } from '../../configuration/configuration';

export const BusinessInfoCard = (props: any) => {
    const { record } = props;
    const showWarning = !record.businessPotential || !record.budget;

    return (
        <Card>
            <CardHeader title="Business Information" />
            <CardContent>
                {showWarning &&
                    <Alert severity="warning">No business case entered.</Alert>
                }
                <SimpleShowLayout  {...props}>
                    {/* <UrlField source="businessCase" /> */}
                    <TextField resource="business" source="businessModel" />
                    {!showWarning && <NumberField resource="business" source="businessPotential" options={CurrencyFormat} />}
                    {!showWarning && <NumberField resource="business" source="budget" options={CurrencyFormat} />}
                    <DateField resource="business" source="startDate" />
                    <DateField resource="business" source="estimatedLaunchDate" />
                </SimpleShowLayout>
            </CardContent>
        </Card>
    )

}
