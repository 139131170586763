import { CardActions, CardHeader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Business, MaturityDescriptionExtended, MaturityScoreExtended } from "../model/ScaleTypes";
import useMaturityModel from "../state/useMaturityModel";
import { Button } from "react-admin";
const OpenAI = require("openai");

const API_KEY = "sk-proj-B7DNRAxCDDlfLy5ZXKh9T3BlbkFJ6335W3FBYHH0puWJldEc"

// Define the type for props
interface ChatGPTAIAppProps {
  business: Business;
  scoresWithNotes: MaturityScoreExtended[];
  maturityDescription: MaturityDescriptionExtended;
}

type Message = {
  role: string,
  content: string,
}

export const ChatGPTAIApp: React.FC<ChatGPTAIAppProps> = ({ business, scoresWithNotes, maturityDescription }) => {
  const { maturityModel, loading: mmLoading } = useMaturityModel();
  const [prompt, setPrompt] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);

  const [examples, setExamples] = useState<Example[]>([]);

  if (mmLoading) return <p>Loading</p>

  if (prompt.length === 0) {
    setPrompt([
      { role: "system", content: `You are an AI startup coach specializing in helping teams develop new business ventures within large enterprises.` },
      {
        role: "system", content:
          `You will be provided with the following information about a project:
* PROJECT NAME
* PROJECT DESCRIPTION
* CURRENT PERSPECTIVE (The area of the project that the team is currently focused on)
* CURRENT OBJECTIVE (The team's desired outcome, please read this carefully, and almost literally)
* DESCRIPTION (Details about the objective)
* EXAMPLES (Actions/evidence suggesting progress towards the objective)
* EARLIER NOTES (History of the team's work)`},
      { role: "system", content: `Your task is to generate a list of testable hypotheses and concrete tests related to the project's objective. These hypotheses should propose a causal relationship between the team's actions and measurable outcomes that indicate progress towards achieving the objective.` },
      {
        role: "system", content: `As an output please provide a list of hypotheses and corresponding tests in the following format:
        
      ### Example [Number]:
\`\`\`json  
      {
         "Hypothesis": "If [team action], then [measurable outcome] will happen because [reasoning]",
         "Test": "[Clear plan to implement the action, collect data, and analyze results]",
         "SuccessCriteria": "[Pre-defined thresholds or benchmarks for the outcome]"
      }
\`\`\`         
`},
      {
        role: "system", content: `
**Additional Guidance:**
* Specifically aim to solve the CURRENT OBJECTIVE, and not the entire project. Focus on what literally the objective says.
* Consider the CURRENT PERSPECTIVE, while other validations might be important, your guidance should be focused on the CURRENT OBJECTIVE.
* Also consider CURRENT LEVEL. It also gives you guidance on what CURRENT OBJECTIVE is related to. For example "Problem Validation" means that the CURRENT OBJECTIVE is related to validating that the problem exists.
* Prioritize hypotheses based on potential impact and feasibility.
* Encourage continuous refinement of hypotheses and tests based on learnings.
* Be aware that some objectives might have qualitative components, not just quantitative ones. 
`},
      {
        role: "user", content: `

        PROJECT NAME: ${business.name}

        PROJECT DESCRIPTION: ${business.description}

        CURRENT PERSPECTIVE: ${maturityDescription.category.name}

        CURRENT OBJECTIVE: ${maturityDescription.name}

        CURRENT LEVEL: ${maturityDescription.level.name}

        DESCRIPTION: ${maturityDescription.description}

        EXAMPLES: ${maturityDescription.examples}

        EARLIER NOTES:
        
        ${scoresWithNotes.map((note, index) => `NOTE FOR LEVEL ${note.level}: 
          ${note.notes}`)}
        
      `},
      { role: "user", content: "Provide me a list of maximum 3 different types of hypotheses, and concrete tests to validate them related to my project so that I can say the objective has been achieved. Please be succint in your answers." }
    ])
  }

  const openai = new OpenAI({
    apiKey: API_KEY,
    dangerouslyAllowBrowser: true
  });

  type Example = {
    Hypothesis: string;
    Test: string;
    SuccessCriteria: string;
  };

  // Function to extract JSON strings from the provided text
  function extractJSONStrings(text: string): string[] {
    const jsonRegex = /```json\s*([\s\S]*?)\s*```/g;
    let match;
    const jsonStrings: string[] = [];

    while ((match = jsonRegex.exec(text)) !== null) {
      jsonStrings.push(match[1]);
    }

    return jsonStrings;
  }

  // Function to parse JSON strings into objects
  function parseExamples(jsonStrings: string[]): Example[] {
    return jsonStrings.map(jsonString => JSON.parse(jsonString));
  }

  async function handleSubmit(): Promise<void> {
    console.log("submit");
    setLoading(true);
    try {
      const result = await openai.chat.completions.create({
        messages: prompt,
        model: "gpt-4o",
        temperature: 0.5,
        max_tokens: 1000,
      });

      console.log("response", result.choices[0].message.content);
      const examples = parseExamples(extractJSONStrings(result.choices[0].message.content));

      console.log("examples", examples);
      setExamples(examples);


    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  return <Card>
    <CardHeader title={
      <Typography variant="button" style={{ fontSize: '0.9em', marginBottom: '10px' }}>🤖 Scale Coach (beta)</Typography>

    } />
    <CardContent>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Hypothesis</TableCell>
              <TableCell>Test</TableCell>
              <TableCell>Success Criteria</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examples.map((example, index) => (
              <TableRow key={index}>
                <TableCell>{example.Hypothesis}</TableCell>
                <TableCell>{example.Test}</TableCell>
                <TableCell>{example.SuccessCriteria}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </CardContent>
    <CardActions>
      <Button label="Suggest targets" type="submit" color="primary" variant='outlined' disabled={loading} onClick={handleSubmit} />
    </CardActions>
  </Card>

};
/*

<>
<div
>
  {discussionHistory
    //.filter(message => message.role !== 'system')
    .length > 0 && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <pre>
          {discussionHistory.map((message, index) =>
            <Card>
              <CardHeader title={message.role}></CardHeader>
              <CardContent>
                <Typography display="block" style={{ textAlign: 'left' }}>{message.content}</Typography>
              </CardContent>
            </Card>
          )}
        </pre>
      </div>
    )}

  <Card>
    <form onSubmit={handleSubmit}>
      <textarea
        value={prompt}
        placeholder="Please ask to openai"
        onChange={(e) => setPrompt(e.target.value)}
      ></textarea>
      <button
        disabled={loading || prompt.length === 0}
        type="submit"
      >
        {loading ? "Generating..." : "Ask"}
      </button>
    </form>
  </Card>

</div>
</>*/
export default ChatGPTAIApp


/*
 **Example answers:**
Hypothesis 1: If we conduct 20 in-depth customer interviews, then we will discover at least 3 critical pain points our product addresses, because thorough qualitative research reveals customers' needs.

Test: Schedule and conduct interviews, analyze for common themes.

Success Criteria: Identify at least 3 significant pain points aligned with our product.

Hypothesis 2: If we run a pilot with 5 customers for 3 months, then we'll see a 15% reduction in their energy consumption, because our platform optimizes usage.

Test: Implement the pilot, collect usage data before and after.

Success Criteria: Achieve an average 15% reduction across participants.
*/